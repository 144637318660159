.privacy {
  background-color: #2c2c2c;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #ffffff;
  padding: 100px 0;
  > * {
    padding: 0 20px;
  }
  > h1 {
    font-size: 3.535vw;
    font-weight: 200;
    line-height: 1.29;
    text-align: left;
    white-space: nowrap;
  }
  > div {
    > h6 {
      font-size: 20px;
      font-weight: 500;
      line-height: 1;
    }
    > p {
      font-size: 15px;
      font-weight: 200;
      line-height: 1.33;
    }
  }
  .title.md-max {
    margin-bottom: 39px;
  }
}
@media (max-width: $breakTablet) {
  .privacy {
    height: 100%;
    flex-direction: column;
  }
  .space {
    padding: 30px;
  }
}
@media (max-width: $breakMobile) {
  .privacy {
    justify-content: flex-start;
    > h1 {
      font-size: 20px;
    }
    > div {
      > h6 {
        font-size: 15px;
      }
      > p {
        font-size: 10px;
      }
    }
  }
}
