$numOfParts: 4;
$animTime: 1s;
$stagger: 0.08s;
$sliderReadyTrans: all $animTime/2 $animTime;
$maxLettersStagger: 6;
$letterStagger: 0.1s;

.slider {
  position: relative;
  color: #fff;
  &:hover {
    .slider__control {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @mixin sliderReady {
    .slider.s--ready & {
      @content;
    }
  }

  &__slides {
    position: relative;
    height: 715px;
    overflow-x: hidden;
    &:hover {
      .slide-cont {
        opacity: 1;
      }
    }
  }
  &__top-heading {
    z-index: $numOfParts * 3;
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    transition: $sliderReadyTrans;
    transform: translateY(-30px);
    opacity: 0;

    @include sliderReady {
      transform: translateY(0);
      opacity: 1;
    }
  }

  &__slide {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    &.s--active {
      pointer-events: auto;
    }

    @mixin slidePrev {
      .slider__slide.s--prev & {
        @content;
      }
    }

    @mixin slideActive {
      .slider__slide.s--active & {
        @content;
      }
    }

    &-content {
      z-index: $numOfParts + 2;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      text-transform: uppercase;
      line-height: 1;
    }

    @mixin subTextsActiveSlide {
      opacity: 0;
      transition: $animTime/2;

      @include slideActive {
        transition-delay: $animTime * 0.65;
        opacity: 1;
        transform: translateY(0);
      }
    }

    &-subheading {
      margin-bottom: 20px;
      font-size: 24px;
      letter-spacing: 2px;
      transform: translateY(20px);

      @include subTextsActiveSlide;
    }

    &-heading {
      $fontSize: 60px;

      display: flex;
      margin-bottom: 20px;
      font-size: $fontSize;
      letter-spacing: 12px;

      span {
        display: block;
        opacity: 0;
        transform: translateY($fontSize * -1);
        transition: all $animTime/3;

        @include slidePrev {
          transform: translateY($fontSize);
        }

        @include slideActive {
          opacity: 1;
          transform: translateY(0);
        }

        @for $i from 1 through $maxLettersStagger {
          &:nth-child(#{$i}) {
            $delay: $letterStagger * ($i - 1);

            transition-delay: $delay;

            @include slideActive {
              transition-delay: $delay + $animTime/3;
            }
          }
        }

        &:nth-child(n + #{$maxLettersStagger + 1}) {
          $delay: $letterStagger * $maxLettersStagger;

          transition-delay: $delay;

          @include slideActive {
            transition-delay: $delay + $animTime/3;
          }
        }
      }
    }

    &-readmore {
      position: relative;
      font-size: 14px;
      text-transform: lowercase;
      backface-visibility: hidden;
      transform: translateY(-20px);
      cursor: pointer;

      @include subTextsActiveSlide;

      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: -3px;
        width: calc(100% + 4px);
        height: calc(100% + 6px);
        background: rgba(255, 255, 255, 0.4);
        transform: scaleX(0.3);
        transform-origin: 0 50%;
        transition: transform 0.3s;
      }

      &:hover:before {
        transform: scaleX(1);
      }
    }

    &-parts {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: 100%;
      height: 715px;

      &:after {
        content: '';
        z-index: $numOfParts + 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.03);
      }
    }

    &-part {
      position: relative;
      width: percentage(1);
      height: 100%;

      $partRef: &;
      $imageFadeAT: $animTime/4;

      &-inner {
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background-size: 0 0;
        background-repeat: no-repeat;
        transition: transform $animTime/2 ease-in-out;
        transform: translateX(30%);
        &.left {
          transform: translateX(-30%);
        }
        &.right {
          transform: translateX(30%);
        }
        &:before {
          content: '';
          position: absolute;
          width: 100vw;
          height: 100%;
          background-image: inherit;
          background-size: cover;
          background-position: center center;
          transition: opacity $imageFadeAT;
          opacity: 0;
        }

        @for $i from 1 through $numOfParts {
          #{$partRef}:nth-child(#{$i}) & {
            $delayOut: ($numOfParts - $i) * $stagger;
            $delayIn: $i * $stagger + $animTime/5;

            z-index: $numOfParts - $i;
            transition-delay: $delayOut;

            @include slideActive {
              transition-delay: $delayIn;
            }

            &:before {
              transition-delay: $delayOut + $imageFadeAT/2;

              @include slideActive {
                transition-delay: $delayIn;
              }
            }
          }
        }

        @include slideActive {
          transform: translateX(0);
          transition-timing-function: ease;

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }

  &__control {
    $size: 50px;

    z-index: 100;
    position: absolute;
    left: 50px;
    top: 50%;
    width: $size;
    height: $size;
    margin-top: $size/-2;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    transform: translateX($size * -1);
    opacity: 0;
    transition: $sliderReadyTrans;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -10px;
      border: 2px solid #000;
      border-bottom: none;
      border-right: none;
      transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      transform: translateX(5px) rotate(-45deg);
    }

    &:hover {
      &:before {
        border-color: #fff;
      }
    }

    &--right {
      left: auto;
      right: 50px;
      transform: translateX($size);

      &:before {
        transform: translateX(-5px) rotate(135deg);
      }
    }
  }
}

.icon-link {
  z-index: 100;
  position: absolute;
  left: 5px;
  bottom: 5px;
  width: 32px;

  img {
    width: 100%;
    vertical-align: top;
  }

  &--twitter {
    left: auto;
    right: 5px;
  }
}

.slide-dots {
  position: absolute;
  right: 10px;
  padding: 0;
  display: flex;
  z-index: 888;
  &__activebar {
    position: absolute;
    width: 40px;
    height: 3px;
    background-color: orange;
    left: 0;
    top: 0;
  }
  &__dots {
    cursor: pointer;
    list-style: none;
    margin: 0 5px;
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
  .active {
    background-color: rgba(255, 255, 255, 1);
  }
}

@media (max-width: $breakTablet) {
}
@media (max-width: $breakMobile) {
  .slider {
    height: 200px;
    padding: 0 10px;
    &__slides {
      height: 200px;
    }
    &__slide {
      &-parts {
        height: 200px;
      }
    }
    &__control {
      opacity: 1;
      transform: translateX(0);
      left: 30px;
      top: 60%;
      $sized: 30px;
      width: $sized;
      height: $sized;
      &--right {
        left: auto;
        right: 30px;
        &:before {
          margin-left: -2px;
        }
      }
      &:before {
        margin-top: -7px;
        width: $sized/3;
        height: $sized/3;
      }
    }
  }
  .slide-dots {
    white-space: nowrap;
    .dots {
      width: 20px;
    }
  }
}
