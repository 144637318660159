@keyframes slogunAnim {
  from {
    transform: translate3d(0, 30px, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, -30px, 0);
    opacity: 1;
  }
}

.slogun {
  max-width: $maxVmWidth;
  white-space: nowrap;
  will-change: opacity;
  &.anim {
    animation: slogunAnim 0.8s ease forwards;
  }
  .title {
    font-size: 20.5px;
    // font-size: 1.068vw;
    font-family: Gilroy;
    font-weight: 800;
    line-height: 1.15;
    text-align: left;
    color: #ffffff;
    margin-bottom: 80px;
  }
  .desc {
    // font-size: 110px;
    font-size: 4.9vw;
    font-family: Applegothicneo;
    font-weight: 600;
    line-height: 1.18;
    text-align: left;
    color: #ffffff;
    max-width: 1004px;
  }

  .content {
    // font-size: 46px;
    font-size: 2.396vw;
    font-family: Gilroy;
    font-weight: 300;
    line-height: 1.3;
    text-align: left;
    color: #ffffff;
    margin-top: 70px;
    max-width: 1004px;
  }
  .en {
    font-size: 1.7vw;
    margin-top: 60px;
  }
}

.third {
  margin-top: 484px;
  margin-bottom: 290px;
  // position: sticky;
  // top: 180px;
  // transition: 0.3s;

  .ko {
    // font-size: 90px;

    font-size: 3.6vw;
    font-family: Applegothicneo;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    transform: translate3d(0, 150%, 0);
    will-change: transform;
  }
  .en {
    // font-size: 40.5px;
    font-size: 1.7vw;
    margin-top: 50px;
    font-family: Gilroy;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: right;
    color: #ffffff;
    transform: translate3d(0, -150%, 0);
    will-change: transform;
    &.md {
      transform: translate3d(0, -230%, 0);
    }
  }
}

.fifth {
  // margin-top: 200px;
  // margin-top: -300px;
  max-width: 1434px;
  .ko {
    // font-size: 90px;
    font-size: 4.3vw;
    font-family: Applegothicneo;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    span {
      color: #2bbf66;
    }
  }
  .en {
    font-size: 1.7vw;
    // font-size: 30px;
    // font-size: 40.5px;
    margin-top: 77px;
    font-family: Gilroy;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }
}

.sixth {
  margin-top: 344px;
  .title {
    font-size: 30px;
    text-align: center;
    font-family: Gilroy;
    font-weight: 800;
    line-height: 1.27;
    color: #ffffff;
  }
  .subtitle {
    font-size: 30px;
    margin: 105px auto;
    font-family: Gilroy;
    font-weight: 800;
    line-height: 1.27;
    text-align: left;
    color: #ffffff;
    .item {
      font-size: 4vw;
      transform: translate3d(0, 40px, 0);

      // font-size: 89.5px;
      margin: 50px auto;
      font-family: Gilroy;
      font-weight: 300;
      line-height: 2.23;
      text-align: center;
      color: #ffffff;
      transition: 0.3s;
      will-change: opacity;
    }
  }
}

.seventh {
  margin-top: 433px;
  .title {
    font-family: Gilroy;
    font-size: 30px;
    font-weight: 800;
    line-height: 2;
    text-align: center;
    color: #ffffff;
    margin-bottom: 102px;
  }
  .partners {
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    .item {
      height: 188px;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 0 50px;
      text-align: center;
      will-change: transform, opacity;
      img {
        min-width: 162px;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.ko,
.en {
  word-break: keep-all;
  &.md {
    display: none;
  }
  &.lg {
    display: block;
  }
}
@media (max-width: $breakTablet) {
  .olive {
    .ko,
    .en {
      &.md {
        display: block;
      }
      &.lg {
        display: none;
      }
    }
  }
  .slogun {
    .desc {
      // font-size: 110px;
      font-size: 6vw;
    }
  }
  .third {
    .ko {
      // font-size: 90px;

      font-size: 5vw;
    }
  }
}

@media (max-width: 770px) {
  .olive {
    .slogun,
    .third,
    .fifth {
      white-space: normal;
      .title,
      .content,
      .en {
        margin-top: 35px;
        font-size: 14px;
      }
    }
    .sixth {
      .title {
        font-size: 26px;
      }
      .subtitle {
        .item {
          font-size: 26px;
        }
      }
    }
    .seventh {
      white-space: normal;
      .title {
        font-size: 26px;
      }
      .partners {
        grid-template-columns: 1fr 1fr;
        .item {
          padding: 0 10px;
          img {
            max-width: 100%;
          }
        }
      }
    }
    .olive-bottom {
      .bottom-slog {
        font-size: 28px;
      }
    }
  }
}

@media (max-width: $breakMobile) {
  .olive {
    .desc,
    .ko {
      font-size: 26px;
    }
    .olive-bottom {
      .bottom-slog {
        font-size: 20px;
        left: 6%;
        top: -2%;
      }
    }
  }
}
