@font-face {
  font-family: 'Gilroy';
  src: url(../font/Gilroy-Light.otf) format('OpenType');
}
@font-face {
  font-family: 'Gilroy';
  src: url(../font/Gilroy-ExtraBold.otf) format('OpenType');
  font-weight: bold;
}
@font-face {
  font-family: 'Applegothicneo';
  src: url(../font/applesdgothicneo_semibold.woff) format('woff'), url(../font/applesdgothicneo_thin.woff) format('woff');
}
@font-face {
  font-family: 'Applegothicneo';
  src: url(../font/applesdgothicneo_light.woff) format('woff'), url(../font/applesdgothicneo_medium.woff) format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Applegothicneo';
  src: url(../font/applesdgothicneo_extrabold.woff) format('woff'), url(../font/applesdgothicneo_heavy.woff) format('woff');
  font-weight: bold;
}
@font-face {
  font-family: 'Applegothicneo';
  src: url(../font/applegothicneo-ultralight.woff) format('woff');
  font-style: italic, oblique;
}
/*@media*/
@media (max-width: $breakTablet) {
}
// @media (max-width: $breakMobile) {
//   .main-slider {
//     .subtitle,
//     .desc {
//       font-size: 15px !important;
//     }
//   }
// }
// @media screen and (min-width: $maxWidth) {
//   .slogun {
//     .title {
//       font-size: 20.5px !important;
//     }
//     .desc {
//       white-space: nowrap;
//       font-size: 110px !important;
//     }
//     .content {
//       font-size: 46px !important;
//     }
//   }
//   .third {
//     .ko {
//       font-size: 90px !important;
//     }
//     .en {
//       font-size: 40.5px !important;
//     }
//   }
//   .fifth {
//     .ko {
//       font-size: 90px !important;
//     }
//     .en {
//       font-size: 40.5px !important;
//     }
//   }
// }
