.olive-footer {
  padding-top: 50px;
}
// .footer {
//   background-color: black;
// }
.company {
  color: #ffffff;
  padding-top: 161px;
  color: #ffffff;
  white-space: nowrap;
  .branch {
    // font-size: 200px;
    font-size: 10.101vw;
    font-family: Gilroy;
    font-weight: 300;
    text-align: left;
  }
  .desc {
    // font-size: 34px;
    font-size: 1.717vw;
    margin-top: 66px;
    font-family: Applegothicneo;
    font-weight: 200;
    line-height: 1.18;
    text-align: left;
    white-space: normal;
    margin-right: 7%;
  }
  .company__info__wrap {
    margin-top: 117px;
    padding-bottom: 111px;
    display: flex;
    justify-content: space-between;
    .company__info {
      text-align: left;
      color: #ffffff;
      .addr_co {
        font-family: Gilroy;
        font-size: 30px;
        font-weight: 800;
      }
      .addr_ko {
        margin-top: 24px;
        font-family: Applegothicneo;
        font-size: 20px;
        font-weight: 200;
      }
      .addr_en {
        margin-top: 18px;
        font-family: Gilroy;
        font-size: 20px;
        font-weight: 300;
      }
      .email_co {
        margin-top: 73px;
        font-family: Gilroy;
        font-size: 20px;
        font-weight: 300;
      }
      .phone_co {
        margin-top: 40px;
        font-family: Gilroy;
        font-size: 20px;
        font-weight: 300;
      }
      .copy_co {
        margin-top: 60px;
        font-family: Gilroy;
        font-size: 20px;
        font-weight: 800;
        line-height: 1.5;
      }
    }
    .company__inbound {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .company__inbound__wrap {
        z-index: $z_slog;
        cursor: pointer;
        .inbound_icon {
          margin: auto;
          background-image: url('/icon/btn-next.png');
          width: 124px;
          height: 32px;
          background-repeat: no-repeat;
          background-position: center 1px;
          background-size: auto;
          border-bottom: 2px solid white;
        }
        .inbound_req {
          margin-top: 24px;
          font-family: Applegothicneo;
          font-size: 26px;
          font-weight: 200;
          color: #ffffff;
        }
      }
    }
  }
}
@media (max-width: $breakTablet) {
  .company__inbound {
    display: none !important;
  }
  .company {
    .desc {
      // font-size: 34px;
      font-size: 2.2vw;
    }
  }
}
@media (max-width: $breakMobile) {
  .company {
    .branch {
      font-size: 50px;
      white-space: normal;
    }
    .desc {
      font-size: 15px;
    }
    .company__info__wrap {
      .company__info {
        .addr_co {
          font-size: 15px;
        }
        .addr_ko,
        .addr_en,
        .email_co,
        .phone_co,
        .copy_co {
          font-size: 10px;
        }
      }
    }
  }
}
