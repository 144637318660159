.contact {
  position: relative;
  padding-top: 322px;
  min-height: 100%;
  font-family: Applegothicneo;

  &::before {
    content: '';
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    z-index: 0;
    background: url('/img/olivestone/contact.jpg') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-attachment: scroll;
  }
  .slogun .content {
    margin-top: 35px;
  }
  .work-container {
    // background-color: black;
    height: 100%;
  }
  > .olive-content {
    > .slogun {
      > .content {
        padding: 6% 0;
        .contact-slogun {
          position: absolute;
        }
      }
    }
  }
  > .desc {
    font-size: 75px;
    font-family: Applegothicneo;
    font-weight: 200;
    line-height: 1.18;
    text-align: left;
    color: #ffffff;
    max-width: 1004px;
  }
}

.form-container {
  color: white;
  max-width: 985px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  font-family: Applegothicneo;
  .title {
    font-size: 27.5px;
    font-weight: 200;
    text-align: left;
    color: #ffffff;
    margin-bottom: 26px;
  }

  .oliveinput {
    position: relative;
    display: flex;
    .oliveinput-container {
      position: relative;
      width: 100%;
      height: 51px;
      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        right: 50%;
        width: 0%;
        height: 1px;
        background-color: #4ea13e;
        transition: 0.5s ease;
        overflow: hidden;
      }
      &.on {
        &::after {
          left: 0;
          right: 0;
          width: 100%;
        }
      }
    }
    .oliveinput-container1 {
      position: relative;
      width: 100%;
      height: 51px;
      &::after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0%;
        right: 0%;
        width: 0%;
        height: 1px;
        background-color: #4ea13e;
        transition: 0.5s ease;
        overflow: hidden;
      }
      &.on {
        &::after {
          width: 100%;
        }
      }
    }
    .label {
      font-size: 15.5px;
      max-width: 147px;
      width: 100%;
      font-weight: 300;
      line-height: 4.19;
      text-align: left;
      color: #fff;
    }
    .input-area {
      flex: 1;
    }

    input {
      position: relative;
      width: 100%;
      height: 51px;
      font-size: 15.5px;
      font-weight: 300;
      line-height: 3.26;
      text-align: left;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #fff;
      color: white;
      padding: 0 15px;

      &:focus {
        &::placeholder {
          opacity: 0;
        }
      }
      &::placeholder {
        color: #fff;
      }
    }

    select {
      width: 100%;
      height: 51px;
      background-color: #292929;
      padding: 0 15px;
      border: none;
      color: #ffffff;
      option {
        color: #ffffff;
      }
    }
  }
  textarea {
    border: none;
    min-height: 208px;
    max-height: 800px;
    width: 100% !important;
    background-color: #292929;
    padding: 25px;
    font-family: Applegothicneo;
    font-size: 15.5px;
    font-weight: 200;
    color: #ffffff;
    resize: vertical;
    &::placeholder {
      color: #fff;
      font-weight: 200;
      font-size: 15.5px;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
      }
    }
  }

  .file-wrap {
    margin-top: 10px;
    width: 100%;
    background-color: #292929;
    padding: 23px 30px;

    .label {
      cursor: pointer;
      text-decoration: underline;
      padding-right: 28px;
      white-space: nowrap;
    }
    div {
      display: inline;
      color: #6d6d6d;
      span {
        cursor: pointer;
        width: 19px;
        height: 19px;
        object-fit: contain;
        border-radius: 50%;
        padding-right: 0;
        display: inline-block;
        background-image: url('/icon/btn-delete.svg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        vertical-align: bottom;
        margin: 0 12px;
      }
    }
  }
  .checkbox-wrap {
    margin-top: 52px;
    font-size: 15.5px;
    font-weight: 200;
    line-height: 2.58;
    color: #ffffff;
    display: flex;
    .policy {
      display: inline-block;
      & > span:nth-child(1) {
        cursor: pointer;
        text-decoration: underline;
      }
      & > span:nth-child(2) {
        padding: 0 9px;
        color: #ff4200;
      }
    }
    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      font-size: 40px;
      font-weight: 200;
      line-height: 1;
      text-align: left;
      color: #4ea13e;
      cursor: pointer;
    }
    .containerr {
      display: inline-block;
      position: relative;
      padding-left: 35px;
      margin-bottom: 12px;
      cursor: pointer;
      font-size: 22px;
      user-select: none;
    }
    .containerr input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
    .checkmark {
      position: absolute;
      top: -5px;
      left: 0;
      width: 22px;
      height: 22px;
      background-color: black;
      border: 1px solid;
    }
    .containerr:hover input ~ .checkmark {
      background-color: #ccc;
    }
    .containerr input:checked ~ .checkmark {
      background-color: #ffffff;
    }
  }
}

.form-gr-select {
  display: flex;
  > div {
    flex: 1;
  }
  > div:nth-child(2) {
    padding-left: 100px;
  }
  .label {
    max-width: 100px !important;
  }
}
.transitionPage {
  width: 100%;
  height: 100%;
}

@media (max-width: $breakTablet) {
  .contact {
    > .olive-content {
      > .slogun {
        > .content {
          padding: 0;
        }
      }
    }
  }
  .form-gr-select {
    display: flex;
    flex-direction: column;
    > div:nth-child(2) {
      margin-top: 10px;
      padding-left: 0;
    }
  }
  .form-container {
    .title {
      font-size: 15px;
    }
    .oliveinput {
      .label {
        max-width: 87px;
      }
      .label,
      input,
      textarea {
        font-size: 12px;
      }
    }
    .checkbox-wrap {
      flex-direction: column;
      & > div:nth-child(2) {
        margin-top: 30px;
        font-size: 28px;
      }
    }
  }
  .f3 {
    padding-bottom: 77px;
  }
}
@media (max-width: $breakMobile) {
  .olive.contact {
    .f3 {
      padding-bottom: 0;
    }
  }
  .contact-slogun {
    font-size: 20px;
  }
  .form-container {
    .file-wrap {
      div {
        display: block;
      }
    }
  }
}
