.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.1s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.1s;
}
