// html {
//   -webkit-overflow-scrolling: touch;
// }
body {
  margin: 0;
  padding: 0;
  background: black;
  font-family: 'Gilroy';
  max-width: 100%;
  height: auto;
  -webkit-font-smoothing: subpixel-antialiased;
  &.fixed {
    overflow: hidden;
  }
}

.youtube {
  position: fixed;
  overflow-y: hidden;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  &.none {
    display: none;
  }
  &.block {
    display: block;
  }
  &:hover {
    cursor: url('/icon/btn-popup-close.png'), auto;
  }

  iframe {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1024px;
    height: 576px;
    transform: translate(-50%, -50%);
    &:hover {
      cursor: url('/icon/btn-popup-close.png'), auto;
    }
  }
}

select {
  -webkit-appearance: none;
}
.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}
.delay {
  animation-delay: 0.4s;
}
.delay-05s {
  animation-delay: 0.5s;
}
.delay-1s {
  animation-delay: 1s;
}

.slick-custom {
  height: 100%;
  display: flex;
  align-items: center;
  å .slick-center {
    // padding: 2em 2em 2em 0em;
    // transform: scale(1.08);
    transition: all 0.8s ease;
  }
  .slick-slide {
    padding: 30px 0;
  }
}
/*no bg*/
.slider-custom {
  .item-wrap {
    .item {
      height: 100vh;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
    img.sm-max {
      width: 100%;
      height: 208px;
    }
  }
}

.slick-dots {
  bottom: 48px !important;
  white-space: nowrap;
  z-index: 88;
  li {
    position: relative;
    margin: 0 !important;
    width: 88px;
    height: 2px !important;
    background-color: white;
    opacity: 0.2;
    button {
      position: absolute;
      left: 0;
      top: -10px;
      width: 88px !important;
      height: 2px !important;
      padding: 10px 5px !important;
    }
    button:before {
      display: none;
    }
    &.slick-active {
      opacity: 1;
    }
  }
}

.bg {
  z-index: $z_canvas;
  background: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  opacity: 0.3 !important; // background: rgba(255, 255, 255, 0.3);
  visibility: visible;

  &.next {
    position: absolute;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .bg-video {
    /***/
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
/*@media*/
@media (max-width: $breakTablet) {
  .youtube {
    iframe {
      width: 700px;
      height: 394px;
    }
  }
  .slick-dots {
    white-space: nowrap;
    li {
      width: 40px;
      button {
        width: 40px !important;
      }
    }
  }
}

@media (max-width: 700px) {
  .youtube {
    iframe {
      width: 400px;
      height: 272px;
    }
  }
}

@media (max-width: 500px) {
  .slick-dots {
    white-space: nowrap;
    li {
      width: 30px;
      button {
        width: 30px !important;
      }
    }
  }
}
@media (max-width: $breakMobile) {
  .slick-dots {
    white-space: nowrap;
    li {
      width: 20px;
      button {
        width: 20px !important;
      }
    }
  }
}
