a {
  text-decoration: none;
}

.olive {
  &.main {
    padding-top: 256px;
    padding-bottom: $nextCaseHeight;
    transition: background 0.2s ease;

    // margin-bottom: $nextCaseHeight;
    &.on {
      transform: translateY(-$nextCaseHeight - 50px);
    }
    &.main.blackout {
      background-color: #000;
    }
  }
  &.contact {
    .f1 {
      padding-top: 252px;
    }
    .f2 {
      padding-top: 96px;
    }
    .f3 {
      padding-top: 111px;
      padding-bottom: 193px;
    }
  }
  .olive-content {
    position: relative;
    margin: 0 auto;
    padding-left: $paddingPc;
    padding-right: $paddingPc;
  }

  .olive-center {
    margin: 0 auto 148px;
  }
  .olive-bottom {
    background: black;
    position: relative;
    .bottom-slog {
      // font-size: 92.5px;
      font-size: 4.4vw;
      z-index: 50;
      font-family: Applegothicneo;
      font-weight: 600;
      line-height: 1.3;
      color: #ffffff;
      position: absolute;
      left: 7%;
      top: -4.5%;
    }
  }
}

.detail {
  padding-bottom: $nextCaseHeight;
}

.sidebar {
  writing-mode: tb-rl;
  width: 52px;
  font-size: 40px;
  font-weight: 800;
  color: #2bbf66;
  font-family: Gilroy;
  letter-spacing: 0.6rem;
  margin: 20px;
  white-space: nowrap;
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  transition: all 1s ease-out;
}

.main-slider {
  .item {
    padding-left: $paddingPc;
  }
}
.lg-only,
.md-min {
  display: block !important;
}
.md-max,
.sm-max {
  display: none !important;
}

/*@media*/
@media (max-width: $breakTablet) {
  .olive {
    .olive-content {
      padding-left: $paddingTablet;
      padding-right: $paddingTablet;
      max-width: $maxWidth;
    }
  }
  .sidebar {
    display: none;
  }
  .main-slider {
    .item {
      padding-left: $paddingTablet;
    }
  }
  .lg-only,
  .sm-max {
    display: none !important;
  }
  .md-max,
  .md-min {
    display: block !important;
  }
}
@media (max-width: $breakMobile) {
  .olive {
    &.main {
      padding-bottom: $nextCaseMobileHeight;
      .first-scene {
        height: 2000px;
      }
      .third {
        top: 340px;
        margin-bottom: 180px;
      }
      .fifth {
        margin-top: -150px;
      }
      .sixth {
        margin-top: 172px;
      }
      .seventh {
        margin-top: 216px;
      }
    }
    &.contact {
      .f1 {
        padding-top: 126px;
      }
    }
    .slogun {
      will-change: opacity;
      .title {
        margin-bottom: 35px;
      }
      .content {
        margin-top: 35px;
      }
    }
    .olive-content {
      padding-left: $paddingMobile;
      padding-right: $paddingMobile;
    }
    .olive-center {
      margin: 240px auto 100px;
    }
  }
  .detail {
    padding-bottom: $nextCaseMobileHeight;
  }
  .main-slider {
    .item {
      padding-left: $paddingMobile;
    }
  }
  .md-min {
    display: none !important;
  }
  .sm-max {
    display: block !important;
  }
}
@media screen and (min-width: $breakMobile) and (max-width: $breakTablet) {
}
@media screen and (min-width: $maxWidth) {
}
