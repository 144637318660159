$ItemMaxWidht: 475px;

.work {
  padding-top: 109px;

  .slogun .content {
    margin-top: 35px;
  }
  .work-content {
    max-width: 1250px;
    width: 100%;
    white-space: normal;
    margin: 100px auto 0;
    padding: 40px;
    .work-item-wrap {
      position: relative;
      margin-top: 200px;
      display: inline-block;
      width: 50%;
      // height: 552px;

      .work-item {
        position: relative;
        width: 100%;
        max-width: $ItemMaxWidht;
        height: $ItemMaxWidht;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: border-radius 0.4s cubic-bezier(1, 0, 0, 1);
        animation: anim 1s ease-in-out infinite alternate;
        cursor: pointer;
        @keyframes anim {
          0% {
            top: 5%;
          }
          100% {
            top: -5%;
          }
        }
        &_click {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        canvas {
          padding: 5%;
          width: 475px;
          height: 475px;
          background-position: center;
          transition: border-radius 0.4s cubic-bezier(1, 0, 0, 1);
        }
      }
      .work-item-sm {
        display: none;
        width: 100%;
        height: 100%;
      }
      .text-wrap {
        top: -82px;
        width: 100%;
        position: absolute;
        font-family: Applegothicneo;
        .text {
          max-width: $ItemMaxWidht;
          width: 100%;
          padding-left: 10px;
          .title {
            font-size: 20px;
            font-weight: 800;
            text-align: left;
            color: #ffffff;
            padding-bottom: 16px;
          }
          .desc {
            font-size: 30px;
            font-weight: 500;
            line-height: 1.13;
            text-align: left;
            color: #ffffff;
          }
        }
      }
      &:nth-child(odd) {
        .work-item {
          margin-right: auto;
        }
        .text-wrap {
          .text {
            margin-right: auto;
          }
        }
      }
      &:nth-child(even) {
        .work-item {
          margin-left: auto;
        }
        .text-wrap {
          .text {
            margin-left: auto;
          }
        }
      }
    }

    .work-item:hover {
      .circle-animated {
        border-radius: 50%;
        transition: border-radius 0.4s cubic-bezier(1, 0, 0, 1);
      }

      // position: absolute;
      border-radius: 50%;
      background-size: cover;
      // max-width: 547px;
      // @extend .circle-animated;

      // .work-item-sm {
      //   @extend .circle-animated;
      // }

      // &:nth-child(odd) {
      //   .work-item {
      //     // left: -44px;
      //   }
      // }
      // &:nth-child(even) {
      //   .work-item {
      //     // right: -44px;
      //   }
      // }
    }
  }
}
@media (max-width: $breakTablet) {
  .work {
    .work-content {
      .work-item-wrap {
        display: block;
        width: 100%;
        &:nth-child(odd),
        &:nth-child(even) {
          .work-item {
            margin: auto;
            left: 0;
            right: 0;
          }
          .text-wrap {
            top: -10%;
            .text {
              margin: auto;
            }
          }
        }
      }
    }
  }
}
@media (max-width: $breakMobile) {
  // max-width: 547px;

  .work {
    .work-content {
      margin: 0 auto;
      padding: 12px;
      .work-item-wrap {
        margin-top: 140px;
        height: auto;
        .work-item {
          max-width: 360px;
          height: 360px;
          &:hover {
            .circle-animated {
              border-radius: 0%;
            }
            border-radius: 0%;
            background-size: cover;
          }
        }
        // .work-item {
        //   display: none;
        // }
        // .work-item-sm {
        //   display: block;
        // }
        &:nth-child(odd),
        &:nth-child(even) {
          .text-wrap {
            top: -60px;
            .text {
              max-width: 360px;
              // margin-right: auto;
              // margin-left: 0;
              .title {
                font-size: 15px;
              }
              .desc {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}
