//애니메이션
@keyframes slideTop {
  from {
    transform: translate3d(0, 300px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}

@keyframes slideTopReverse {
  from {
    transform: translate3d(0, 0px, 0);
  }
  to {
    transform: translate3d(0, 350px, 0);
  }
}

@keyframes slideBottom {
  from {
    transform: translate3d(0, -250px, 0);
  }
  to {
    transform: translate3d(0, 0px, 0);
  }
}

@keyframes slideBottomReverse {
  from {
    transform: translate3d(0, 0px, 0);
  }
  to {
    transform: translate3d(0, -300px, 0);
  }
}

@keyframes routerTransition {
  from {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, 150px, 0);
    opacity: 0;
  }
}

@keyframes navTransition {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes desc {
  from {
    transform: translate3d(0, 0px, 0);
  }
  to {
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes desc2 {
  from {
    transform: translate3d(0, 0px, 0);
  }
  to {
    transform: translate3d(0, 200%, 0);
  }
}

@keyframes desc3 {
  from {
    transform: translate3d(0, 150%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes desc4 {
  from {
    transform: translate3d(0, -150%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

body.off .main {
  background-color: #000;
  transition-delay: 0.1s;
}

body.off .bg {
  opacity: 0 !important;
  transition-delay: 0.1s;
}

body.off .nav__container,
body.off .slogun.left .title,
body.off .text-wrap,
body.off .olive-center,
body.off .footer,
body.off .detail-item,
body.off .detail-members,
body.off .sticky-elem,
body.off .sidebar,
body.off .main-slider,
body.off .next,
body.off .work .work-content .work-item-wrap .work-item,
body.off .f1,
body.off .f2,
body.off .f3,
body.off .contact,
body.off .olive-bottom-slider {
  animation: navTransition 0.5s ease 0.1s forwards;
}

body.off .desc_wrap > div,
body.off .title,
body.off .content,
body.off .ko,
body.off .en {
  animation: desc 0.6s ease 0.1s forwards;
}

body.off .title,
body.off.form-container .title {
  animation: desc2 0.6s ease 0.1s forwards;
}

body .third.on .ko.lg,
body .third.on .ko.md {
  animation: desc3 0.6s ease forwards;
}

body .third.on .en.lg,
body .third.on .en.md {
  animation: desc4 0.6s ease forwards;
}

body.off .third.on .ko.lg {
  animation: slideTopReverse 0.6s ease forwards;
}

body.off .third.on .en.lg {
  animation: slideBottomReverse 0.6s ease forwards;
}

body.off .fifth .ko.lg,
body.off .fifth .en.lg,
body.off .sixth .title,
body.off .seventh .title,
body.off .main-slider .title {
  animation: none;
}

body.off .next-case-content h6,
body.off .next-case-content .next-case-line,
body.off .next-case-content svg {
  animation: navTransition 0.1s ease forwards;
}
.sticky-elem {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.hiddenTop,
.hiddenBottom {
  display: inline-block;
  transition: 0.3s;
  will-change: transform;
}

body.off .scene .fifth .hiddenTop,
.scene .fifth .hiddenTop {
  animation: slideTopReverse 0.7s ease forwards;
  transform: translate3d(0, 300px, 0);
}
.scene .fifth.on .hiddenTop {
  animation: slideTop 0.8s ease forwards;
}

body.off .scene .fifth .hiddenBottom,
.scene .fifth .hiddenBottom {
  animation: slideBottomReverse 0.7s ease forwards;
  transform: translate3d(0, -250px, 0);
}
.scene .fifth.on .hiddenBottom {
  animation: slideBottom 0.8s ease forwards;
}

.desc_wrap {
  overflow: hidden;
}

@media (max-width: $breakMobile) {
  .sticky-elem {
    padding-top: 150px;
  }
}
