html,
body,
#root {
  width: 100%;
  height: 100%;
  /* -webkit-overflow-scrolling: auto; */
}
* {
  outline: none;
  box-sizing: border-box;
}
