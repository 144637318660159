.modal-container {
  z-index: $z_modal;
  position: relative;
  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .modal:hover {
    cursor: url('/icon/btn-popup-close.png'), auto;
  }
  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media (max-width: $breakTablet) {
}
@media (max-width: $breakMobile) {
}
@media screen and (min-width: $maxWidth) {
  .modal {
    .modal-content {
      > h1 {
        font-size: 70px;
      }
    }
  }
}
