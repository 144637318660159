/**
  * layout
  * max-width: 1630px;
  * w1920 -(ml 140 + mr 150)
  */
$maxWidth: 1800px;
$maxVmWidth: 1234px;
$breakTablet: 1080px;
$breakMobile: 480px;

$paddingPc: 140px;
$paddingMobile: 17px;
$paddingTablet: 50px;

$nextCaseHeight: 456px;
$nextCaseMobileHeight: 109px;
$delay: 0.4s;
$delayFast: 0.4s;
/**
  * color
  */
$gray: #4b4e50;
$descGray: #6f6f6f;
$black: black;
/**
  *priority z-index
  */
$z_canvas: -1;
$z_bg: -2;
$z_bg_content: -3;
$z_slog: 1;
$z_overlay: 2;
$z_overlay_content: 3;
$z_toggle_btn: 4;
$z_overlay_side: 5;
$z_modal: 10;
/**
  *test
  */
.test {
  background-color: red;
  .drag-wrap {
    border: 1px solid;
    background-color: white;
    display: inline-block;
  }
}
