.nav__container {
  width: 100%;
  // height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  top: 0;
  left: 0;
  z-index: 88;
  .nav__bar {
    background: white;
    width: 100%;
    height: 50px;
    transition: background-color 800ms linear;
    .nav__content {
      // max-width: $maxWidth;
      // padding: 0 255px;
      padding: 0 7%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      .nav__logo {
        cursor: pointer;
        height: 100%;
        width: 133px;
        background-image: url('/img/olivestone/logo-olivestone.png');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
      }
      .nav__menu {
        display: flex;
        div {
          position: relative;
          cursor: pointer;
          margin-left: 38px;
          font-family: Gilroy;
          font-size: 15px;
          font-weight: 800;
          line-height: 3;
          color: #000000;

          &::after {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 3px;
            left: 50%;
            bottom: 6px;
            transform: translateX(-50%);
            transition: 0.4s ease-in;
          }
          a {
            text-decoration: none;
            width: 100%;
            height: 100%;
            color: inherit;
          }
        }
        .link:nth-child(2) {
          margin-left: 22px;
        }
        .youtube_link {
          position: relative;
          margin-top: 13px;
          width: 44px;
          height: 19px;
          overflow: hidden;
          background-color: #39c069;
          .animation-wrap {
            margin: 0;
            padding: 0;
            display: flex;
            width: 44px;
            height: 19px;
            overflow: hidden;
            .animation {
              position: relative;
              margin: 0 0 0 -1px;
              padding: 0;
              font-size: 0;
              min-width: 180px;
              height: 19px;
              transform: translateX(-200%);
              background-image: url('/img/olivestone/arrow_img.png');
              background-position: center center;
              background-repeat: repeat;
              background-color: #39c069;
              overflow: hidden;
              animation: identifier 7s linear infinite;
              div {
                position: absolute;
                left: -10px;
                margin: 0;
                padding: 0;
                transform: rotate(45deg);
                width: 19px;
                height: 100%;
                border-right: 3px solid black;
                border-top: 3px solid black;
                // &.second {
                //   left: 0px;
                // }
                // &.third {
                //   left: 10px;
                // }
                // &.fourth {
                //   left: 20px;
                // }
              }
            }
            @keyframes identifier {
              100% {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
  }
  .nav__bar.fixed {
    background-color: transparent;
    position: fixed;
    top: 0;
    .nav__menu {
      div {
        color: white;
      }
    }
  }
}

.nav__side__wrap.open {
  .nav__sdie__overlay {
    visibility: visible;
    z-index: 90;
    transition: 0.5s ease 0.1s;
    .nav__side {
      transform: translateX(0px) !important;
      // transition: transform 0.3s ease-out;
    }
  }
}
.nav__side__wrap {
  .nav__side__menu {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 89;
    .nav__side__content {
      max-height: 350px;
      height: 100%;
      text-align: right;
      padding-right: 36px;
      padding-bottom: 30px;
      margin-top: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .logo {
        width: 80px;
        height: 20px;
        background-image: url(/img/olivestone/logo-olivestone.png);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        margin-left: auto;
      }

      .link {
        cursor: pointer;
        margin-left: auto;
        font-family: Gilroy;
        font-size: 15px;
        font-weight: 800;
        text-align: right;
        color: #000000;
        a {
          text-decoration: none;
          width: 100%;
          height: 100%;
          color: inherit;
        }
      }
    }
    .nav__side__space {
      min-height: 100px;
    }
  }
  .nav__sdie__overlay {
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 120vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
    transition: 0.2s ease 0.1s;
    .nav__side {
      position: fixed;
      max-width: 180px;
      width: 100%;
      top: 0;
      margin: 0;
      height: 100%;
      // background-color: #fff;
      z-index: $z_overlay_content;
      overflow-y: auto;
      will-change: transform;
      backface-visibility: hidden;
    }
    .nav__side.left {
      left: 0 !important;
      animation: moverLeft 0.3s ease;
      transform: translateX(-105%);
    }
    .nav__side.right {
      right: 0 !important;
      animation: moverRight 0.3s ease;
      transform: translateX(105%);
    }
  }
}

.nav__side__toggle {
  z-index: 89;
  display: none;
  position: fixed;
  bottom: 50px;
  right: 25px;
  width: 49px;
  height: 49px;
  background-image: url('/icon/ic-menu.png');
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
  cursor: pointer;
}

.nav__side__menu_wrap {
  display: none;
}
.nav__side__menu {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .nav__side__content {
    max-height: 350px;
    height: 100%;
    text-align: right;
    padding-right: 36px;
    padding-bottom: 30px;
    margin-top: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    li {
      list-style: none;
    }
    .youtube_link {
      float: right;
      position: relative;
      margin-top: 13px;
      width: 44px;
      height: 19px;
      overflow: hidden;
      background-color: #39c069;
      .animation-wrap {
        margin: 0;
        padding: 0;
        display: flex;
        width: 44px;
        height: 19px;
        overflow: hidden;
        .animation {
          position: relative;
          margin: 0 0 0 -1px;
          padding: 0;
          font-size: 0;
          min-width: 180px;
          height: 19px;
          transform: translateX(-200%);
          background-image: url('/img/olivestone/arrow_img.png');
          background-position: center center;
          background-repeat: repeat;
          background-color: #39c069;
          overflow: hidden;
          animation: identifier 7s linear infinite;
        }
        @keyframes identifier {
          100% {
            transform: translateX(0);
          }
        }
      }
    }
    .logo {
      display: block;
      width: 80px;
      height: 20px;
      background-image: url(/img/olivestone/logo-olivestone.png);
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      margin-left: auto;
    }
    .link {
      cursor: pointer;
      margin-left: auto;
      font-family: Gilroy;
      font-size: 15px;
      font-weight: 800;
      text-align: right;
      color: #000000;
      a {
        text-decoration: none;
        width: 100%;
        height: 100%;
        color: inherit;
      }
    }
  }
  .nav__side__space {
    min-height: 100px;
  }
}

@media (max-width: $breakTablet) {
  .nav__container {
    z-index: 88;
    .nav__bar {
      background-color: transparent;
      position: fixed;
      top: 0;
      .nav__menu {
        visibility: hidden;
      }
      .nav__content {
        .nav__logo {
          background: none;
        }
      }
    }
  }

  @keyframes anim {
    from {
      z-index: 88;
    }
    to {
      z-index: -2;
    }
  }

  .nav__side__menu_wrap {
    display: block;
    top: 0;
    z-index: -2;
    &.open {
      z-index: 99;
      transition: z-index 0s 0s;
    }
    transition: z-index 0s 0.7s;
  }
  .nav__side__toggle {
    display: block;
  }
}
@media (max-width: $breakMobile) {
  .nav__side__menu {
    .nav__side__content {
      max-height: 250px;
      .link {
        font-size: 20px;
      }
    }
  }
  .nav__container {
    .nav__bar {
      background-color: transparent;
      position: fixed;
      top: 0;
    }
  }
  .nav__side__toggle {
    right: 25px;
  }
}
