.main-slider {
  position: relative;
  width: 100%;
  height: 3000px;
  // margin: auto;
}

.sticky-slider {
  position: sticky;
  top: 220px;
  // -webkit-box-orient: horizontal;
  // -webkit-box-direction: normal;
}

.olive-bottom-slider {
  &:hover {
    .slick-arrow {
      opacity: 1;
    }
  }
  .slick-arrow {
    position: absolute;
    z-index: 4;
    top: 50%;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
    opacity: 0;
    transition: opacity 0.4s cubic-bezier(0.6, -0.28, 0.735, 0.045);

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-left: -10px;
      margin-top: -10px;
      border: 2px solid #000;
      border-bottom: none;
      border-right: none;
      transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
      transform: translateX(5px) rotate(-45deg);
    }

    &:hover {
      &:before {
        border-color: #fff;
      }
    }

    &.slick-prev {
      left: 10% !important;
    }

    &.slick-next {
      right: 10% !important;
      &:before {
        transform: translateX(-5px) rotate(135deg);
      }
    }
  }
}

.slick-slide {
  .slick-track {
    overflow: hidden;
    will-change: transform !important;
  }
  .item {
    display: flex !important;
    & > div:nth-child(1) {
      flex: 1;
    }
    & > div:nth-child(2) {
      flex: 3;
      margin-top: 26px;
      justify-content: space-around;
      display: flex;
    }
    .line {
      width: 8vw;
      height: 6px;
      background-color: #ffffff;
    }
    .title {
      font-size: 6.1vw;
      // font-size: 140px;
      font-family: Gilroy;
      font-weight: 700;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.57;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
    .subtitle {
      font-size: 1.38vw;
      // font-size: 26.5px;
      margin-top: 50px;
      font-family: Applegothicneo;
      font-weight: 200;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.51;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
    .desc {
      font-size: 18px;
      margin-top: 20px;
      font-family: Gilroy;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      text-align: left;
      color: #ffffff;
    }
  }
}

/*@media*/
@media (max-width: $breakTablet) {
  .item {
    .title {
      font-size: 8vw;
    }
  }
}
@media (max-width: $breakMobile) {
  .sticky-slider {
    top: 160px;
    // -webkit-box-orient: horizontal;
    // -webkit-box-direction: normal;
  }

  .olive-bottom-slider {
    .slick-arrow {
      opacity: 1;
    }
  }

  .slick-slide {
    .item {
      .line {
        width: 30px;
        height: 2px;
      }
      .title {
        font-size: 56px;
      }
      .subtitle,
      .desc {
        font-size: 17px;
      }
    }
  }
}
