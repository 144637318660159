.detail {
  font-family: Applegothicneo;
  position: relative;
  z-index: 1;
  &.preview {
    .bg {
      opacity: 0.3 !important;
    }
    .detail-item div {
      opacity: 1 !important;
    }
  }
  .detail-item {
    padding: 0;
    text-align: center;

    video {
      max-width: 100%;
    }
    .center {
      text-align: center;
      padding-left: 160px;
      padding-right: 160px;
      padding-bottom: 30px;
    }
    img {
      &.full {
        margin-bottom: -4px;
      }
    }
    .full {
      width: 100% !important;
    }
    .video_wrap {
      font-size: 0;
    }
    img {
      max-width: 100%;
    }
    img.center {
      padding-top: 160px;
      padding-bottom: 160px;
    }
    .text {
      word-break: break-all;
      width: 68%;
      margin: 0 auto;
      font-size: 22px;
      font-weight: 500;
      line-height: 1.5;
      text-align: center;
    }
  }
  .detail-members {
    background-color: black;
    color: #ffffff;
    text-align: left;
    padding-top: 105px;
    padding-bottom: 105px;
    h5 {
      font-size: 50px;
      font-weight: 500;
      line-height: 1;
    }
    h6 {
      font-size: 23px;
      font-weight: 800;
      line-height: 1.58;
    }
    > div {
      font-size: 16.5px;
      td:nth-child(1) {
        color: #9d9d9d;
        width: 327px;
      }
    }
  }
}

.masthead {
  background-color: black;
  position: relative;
  color: white;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  .bg {
    position: absolute;
    z-index: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    pointer-events: none;
    object-fit: cover;
    overflow: hidden;
    .bg.black {
      position: static;
      background: #000;
    }
  }

  .text-wrap {
    width: 100%;
    padding-top: 218px;
    padding-bottom: 237px;
    max-width: 1000px;
    h6 {
      font-size: 40px;
    }
    h1 {
      font-size: 5.051vw;
    }
    p {
      font-size: 18px;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

@media (max-width: $breakTablet) {
  .detail {
    .detail-item {
      .center {
        padding: 20px 20px 30px;
      }
      .text {
        font-size: 18px;
      }
    }
  }
  .masthead {
    .text-wrap {
      padding-top: 330px;
      max-width: 232px;
      h6 {
        font-size: 20px;
      }
      h1 {
        font-size: 34px;
      }
      p {
        font-size: 15px;
      }
    }
  }
}
@media (max-width: $breakMobile) {
  .detail {
    .detail-item {
      .text {
        font-size: 16px;
      }
    }
    .detail-members {
      h5 {
        font-size: 19px;
      }
      h6 {
        font-size: 15px;
      }
      > div {
        font-size: 10px;
        td:nth-child(1) {
          width: 100px;
        }
      }
    }
  }
}
@media screen and (min-width: $maxWidth) {
  .masthead {
    .text-wrap {
      h6 {
        font-size: 40px;
      }
      h1 {
        font-size: 100px;
      }
      p {
        font-size: 18px;
      }
    }
  }
}
