.drag-bar {
  cursor: pointer;
  position: fixed;
  bottom: 100px;
  right: 60px;
  width: 50px;
  z-index: 9999;
  // background-color: blanchedalmond; //to be deleted
}

.drag-bar.scroll {
  svg,
  .proxy-btn {
    opacity: 1;
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.drag-proxy-btn {
  position: absolute;
  top: 3px;
  left: 0.3px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
  svg {
    will-change: opacity;
    opacity: 0;
  }
  path {
    position: absolute;
    width: 58px;
    transition: 0.3s ease;
    stroke: rgb(43, 191, 102);
    z-index: 2;
  }

  .proxy-bg {
    transform: translate(5px, 5px);
    z-index: 1;
    stroke: #fff;
  }
}

/*@media*/
@media (max-width: $breakTablet) {
  .drag-bar {
    display: none;
  }
}
@media (max-width: $breakMobile) {
}
