.effectFadeAway {
  animation: fadeAway 0.5s;
}

@keyframes drawUp {
  to {
    height: 100vh;
  }
}
@keyframes showUp {
  to {
    opacity: 1;
  }
}
@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes fadeAway {
  to {
    opacity: 0;
  }
}
@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.willCahnge {
  will-change: transform, opacity;
}
