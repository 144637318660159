.next-case-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: $z_bg;
  &.readyToPaging {
    z-index: 0;
  }
  &.active {
    .next-case-content {
      animation: fadeAway $delay;
    }
  }
  /*
  &.active {
    z-index: $z_overlay;
    .bg {
      display: none;
    }
    .next-case {
      background-color: black;
      background-image: none !important;
    }
    .next-case {
      z-index: 1;
      animation: drawUp $delay;
      .next-case-content {
        opacity: 0;
        transition: opacity $delayFast step-start;
        .next-case-title {
          text-overflow: ellipsis;
          white-space: normal;
        }
      }
    }
  }
*/
}

.next-case {
  cursor: pointer;
  width: 100%;
  height: $nextCaseHeight;
  position: absolute;
  bottom: 0;
  /**/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  .next-case-content {
    scroll-snap-align: start;
    width: 100%;
    padding-top: 106px;
    padding-bottom: 144px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    color: #ffffff;
    > div:nth-child(1) {
      display: flex;
      align-items: center;

      h6 {
        font-family: Gilroy;
        font-size: 26px;
        font-weight: 800;
        line-height: 0.56;
        text-align: left;
        padding-right: 30px;
      }
    }

    .next-case-line {
      border-bottom: 2px solid white;
      height: 2px;
      flex: 1;
    }
    .next-case-title {
      width: 100%;
      height: 60px;
      font-size: 45px;
      font-weight: 500;
      line-height: 1.2;
      letter-spacing: 1.4px;
      text-align: left;
      white-space: nowrap;
    }
    svg {
      width: 100%;
    }
    svg text {
      fill: white;
      stroke: white;
      stroke-width: 2;
    }
  }
}

.next-case.on {
  svg text {
    animation: stroke 2s linear 0.3s;
  }
}
@keyframes width {
  0% {
    width: 0;
    opacity: 0;
  }
  20% {
    width: 10%;
    opacity: 0;
  }
  100% {
    width: 100%;
    opacity: 1;
  }
}
@keyframes stroke {
  0% {
    fill: transparent;
    stroke: #fff;
    fill: transparent;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  60% {
    fill: transparent;
    stroke: #fff;
  }
  70% {
    fill: transparent;
    stroke: #fff;
    stroke-width: 3;
  }
  80% {
    stroke: #fff;

    stroke-dasharray: 50% 0;
    stroke-width: 3;
  }
  100% {
    fill: #fff;
  }
}
@media (max-width: $breakMobile) {
  .next-case {
    height: $nextCaseMobileHeight;
    .next-case-content {
      padding-top: 24px;
      padding-bottom: 24px;
      > div:nth-child(1) {
        height: 11px;
        h6 {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.33;
        }
      }
      .next-case-title {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.33;
        max-width: 100%;
      }
    }
  }
}
